/* eslint-disable react/prop-types */
import React from 'react'
import styles from './style.module.css'
import SignContent from 'src/components/modal/sign/sign-content'
import BackIcon from 'src/assets/images/backIcon'
import ResetContent from 'src/components/modal/reset-password/reset-content'
import { Link } from 'react-router-dom'
import Title from 'src/components/modal/modals-components/title'
import { useModal } from 'src/lib/providers/modal'

const ResetPassword = ({ email = '' }) => {
    const { closeModal } = useModal()

    return (
        <>
            <div className={styles.wrapperReset}>
                <Title
                    handleCloseModal={closeModal}
                    title="Password reset"
                    subTitle={`We sent a link to ${email}`}>
                    <BackIcon onClick={closeModal} />
                </Title>
                <ResetContent />
            </div>
            <div className={styles.modalFormBox}>
                <SignContent step="2">
                    <div className={styles.linkBox}>
                        Did not receive the email?{' '}
                        <Link className={styles.link} to="#">
                            Resend
                        </Link>
                    </div>
                </SignContent>
            </div>
        </>
    )
}

export default ResetPassword
