import React, { useCallback } from 'react'
import styles from './style.module.css'
import Button from '../button'
import tennisImage from 'src/assets/images/stub_racket.png'
import { useNavigate } from 'react-router'
import { useModal } from 'src/lib/providers/modal'
import { MODAL } from '../layouts/Layout'
import { toUSD } from '../bidding/bid-form'

const OwnedCard = ({ lot }) => {
    const navigate = useNavigate();
    const { openModal } = useModal();
    const lotPath = `/auction/${lot.auction.uuid}/${lot.uuid}`;

    const openCelebrity = useCallback(() => {
        openModal(MODAL.CONTENT_MODAL, {
            title: lot?.celebrity?.name,
            description_full: lot?.celebrity?.description_full,
            cover_image: lot?.celebrity?.cover_image,
        })
    }, [lot?.celebrity?.name])

    return (
        <div className={styles.card}>
            <div className={styles.gridContainer}>
                <div className={styles.lotAbout}>
                    <div className={styles.lotTitleBox}>
                        <div>
                            <div className={styles.subTitle}>The Item</div>
                            <div
                                style={{
                                    fontFamily: 'Aeonik',
                                    fontSize: '27px',
                                    lineHeight: '31px',
                                    marginTop: '16px',
                                }}>
                                {lot.title}
                            </div>
                        </div>
                        <div className={styles.imageContainerMobile}>
                            {' '}
                            <img
                                src={lot['cover_media']['media_file']}
                                alt="image"
                                className={styles.image}
                            />
                        </div>
                        <div className={styles.buttonDetail}>
                            <Button variant="neutral-dark" fullWidth={false} onClick={() => navigate(`${lotPath}`)}>
                                Item details
                            </Button>
                        </div>
                    </div>
                    <div className={styles.imageContainerDesk}>
                        {' '}
                        <img
                            src={lot['cover_media']['media_file']}
                            alt="image"
                            className={styles.image}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.charityBox}>
                <div className={`${styles.textContent} `}>
                    <div className={styles.subTitle}>The Charity</div>
                    <div className={styles.title}>{lot.foundation.title}</div>
                    <div className={`${styles.text} ${styles.truncate}`}>
                        <p>
                            {lot.foundation['description_short']}
                        </p>
                        <button className={styles.learn} onClick={() => openModal(MODAL.CONTENT_MODAL, lot.foundation)}>Learn more</button>
                    </div>
                </div>
                <div className={styles.textContent}>
                    <div className={styles.subTitle}>THE CELEBRITY</div>
                    <div className={styles.title}>{lot.celebrity?.name}</div>
                    <div
                        className={`${styles.text} ${styles.truncate} ${styles.celebrity}`}>
                        <p>
                            {lot.celebrity?.description_short}
                        </p>
                        <button className={styles.learn} onClick={openCelebrity}>Learn more</button>
                    </div>
                </div>
                <div className={styles.textContent}>
                    <div className={styles.subTitle}>Your donation</div>
                    <div className={styles.title}>{toUSD(lot.last_bid)}</div>
                </div>
            </div>
            <div className={styles.paymentInfo}>
                <div className={styles.paymentFlex}>
                    <p className={styles.subTitle}>Item timeline</p>
                    <ul>
                    {lot.timeline.map((el, i) => (
                        <li key={i}>
                            <div>
                                <p>{el.text}</p>
                            </div>
                        </li>
                        // <OwnedCard key={i} lot={el} isHighestBidder={Math.random() >= 0.5} />
                    ))}
                        {/* <li>
                            <div>
                                <p>Winning bid</p>
                                <p>$10,000.00</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <p>Payment processed</p>
                                <p>VISA 8192</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <p>Item mailed</p>
                                <p>5015 Evans Ave, Los Angeles, CA</p>
                            </div>
                        </li>
                        <li style={{ opacity: '0.4' }}>
                            <div>
                                <p>August 8, 2024</p>
                                <p> Connected to Metamask</p>
                            </div>
                        </li> */}
                    </ul>
                    {/* <Button>Connect wallet</Button> */}
                </div>
            </div>
        </div>
    )
}

export default OwnedCard
