import styles from './style.module.css'
const UploadFileButton = ({
    text = 'Upload file',
    onChange,
    ...otherProps
}) => {
    return (
        <label className={`${styles.fileButton}`}>
            <input type="file" onChange={onChange} {...otherProps} />
            {text}
        </label>
    )
}

export default UploadFileButton
