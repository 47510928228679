import { Col, Container, Row } from 'react-bootstrap'
import { Navigate, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import TabsComponent from 'src/components/tabs'
import UserHero from 'src/components/user-profile-hero'
import BidList from 'src/components/bidList'
import OwnedList from 'src/components/owned-list'
import ImpactList from 'src/components/impact-card-list'
import LotCard from 'src/components/lot/lot-card'
import { useAuth } from 'src/lib/providers/auth'
import PathConstants from 'src/routes/pathConstants'
import { useMyWatchList } from 'src/lib/hooks/user'
import EmptyList from '../components/empty-list'

const WatchList = () => {
    const [{ items: watchlistItems }] = useMyWatchList({});

    return (watchlistItems.length == 0 ? (
        <EmptyList>
            <h4>
                You currently don't have any items on your
                watchlist.
            </h4>
            <p>
                Start exploring our auctions to add lots and
                stay updated on your favorites.
            </p>
        </EmptyList>
    ) : (
        <Container>
            <Row xs={1} md={2} xl={3} className="g-4">
                {watchlistItems.map(lot => (
                    <Col key={lot.uuid}>
                        <LotCard
                            lot={lot}
                            auctionUUID={lot.auction}
                            onClick={() =>
                                console.log('click')
                            }
                        />
                    </Col>
                ))}
            </Row>
        </Container>
    ));
}

export default function Profile() {
    const { user } = useAuth()

    const [activeTab, setActiveTab] = useState('watchlist')
    const location = useLocation()

    if (!user) return <Navigate to={PathConstants.HOME} />

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const tab = params.get('tab') || 'watchlist'
        setActiveTab(tab)
    }, [location.search])

    const tabs = [
        {
            name: 'Watchlist',
            key: 'watchlist',
        },
        {
            name: 'My bids',
            key: 'myBids',
        },
        {
            name: 'Owned',
            key: 'owned',
        },
        {
            name: 'My impact',
            key: 'myImpact',
        },
    ]

    const demoLots = [
        {
            uuid: 'e19cdbde-b485-42a9-8ea9-da535af5428a',
            auction: 'c037843e-068e-4dd1-8fbe-5ad99ea9146f',
            title: 'Björn Borg’s 1978 Wimbledon Champion Racket',
            description:
                "This is the iconic racket used by Björn Borg in the legendary 1978 Wimbledon final. As one of the greatest tennis players in history, Borg's prowess on the court was unparalleled, and this racket symbolizes his dominance during an era that saw him capture five consecutive Wimbledon titles from 1976 to 1980.",
            date_start: '2024-08-02T00:51:59Z',
            date_end: '2024-10-15T00:52:09Z',
            estimate_low: 100,
            estimate_high: 1000,
            cover_media: {
                media_type: 'image',
                media_file:
                    'https://memora-api.mysu.pro/backend/media/ab082e51d1094d6084884b9544d3b765.png',
            },
            gallery_media: [
                {
                    media_type: 'image',
                    media_file:
                        'https://memora-api.mysu.pro/backend/media/f179c86152c14a52b35deeabaa2b721c.png',
                },
                {
                    media_type: 'image',
                    media_file:
                        'https://memora-api.mysu.pro/backend/media/462eba67faf24cc0a74871bdf8393978.png',
                },
                {
                    media_type: 'image',
                    media_file:
                        'https://memora-api.mysu.pro/backend/media/fa2b52d5380d44e5ba81060993568a9d.png',
                },
                {
                    media_type: 'image',
                    media_file:
                        'https://memora-api.mysu.pro/backend/media/a74963b329124f2a82cfc71d93b53ef8.png',
                },
            ],
            promo_video:
                'https://www.youtube.com/embed/QyktFwyCgWY?si=JVyP4JYDvo6kLYuN',
            list_image:
                'https://memora-api.mysu.pro/backend/media/e19cdbdeb48542a98ea9da535af5428a.png',
            celebrity: {
                name: 'Bjorn Borg',
                description_full:
                    "Björn Borg is a Swedish tennis legend renowned for his ice-cool demeanor and relentless baseline play. Rising to prominence in the 1970s, Borg captured the tennis world’s attention with his unmatched skill and mental fortitude. He is best known for winning five consecutive Wimbledon titles from 1976 to 1980, a feat that cemented his status as one of the greatest players in the history of the sport. Borg's unique two-handed backhand and tireless work ethic revolutionized the game, making him a beloved figure in tennis lore.\r\n\r\nToday, Björn Borg enjoys a fulfilling life beyond the tennis courts. He is a successful entrepreneur, having founded the Björn Borg fashion label, which specializes in sportswear and underwear. His brand has gained international recognition, combining his iconic name with stylish, high-quality products.\r\n\r\nOutside of business, Borg is a devoted family man. He enjoys spending time with his wife and children, living a relatively private life away from the public eye. Despite stepping back from professional tennis, he remains connected to the sport, often participating in tennis exhibitions and mentoring young players. Björn Borg's legacy continues to inspire new generations of players and fans around the globe, proving that his influence extends far beyond his years on the court.",
                cover_image:
                    'https://memora-api.mysu.pro/backend/media/3659014928e44ea28f299ee3417b7dcb.png',
            },
            memorabilia: 'Wimbeldon Championship Racket 1978',
            foundation: {
                uuid: 'f725c4c8-cb9b-4a07-b764-474aa7e812f9',
                title: 'Americas for the Arts',
                description_short:
                    'All proceeds from this auction will be donated to the Muhammad Ali Center, a non-profit museum and cultural center in Louisville, Kentucky. The Center is dedicated to preserving and sharing the',
                description_full:
                    "Björn Borg is a Swedish tennis legend renowned for his ice-cool demeanor and relentless baseline play. Rising to prominence in the 1970s, Borg captured the tennis world’s attention with his unmatched skill and mental fortitude. He is best known for winning five consecutive Wimbledon titles from 1976 to 1980, a feat that cemented his status as one of the greatest players in the history of the sport. Borg's unique two-handed backhand and tireless work ethic revolutionized the game, making him a beloved figure in tennis lore.\r\n\r\nToday, Björn Borg enjoys a fulfilling life beyond the tennis courts. He is a successful entrepreneur, having founded the Björn Borg fashion label, which specializes in sportswear and underwear. His brand has gained international recognition, combining his iconic name with stylish, high-quality products.\r\n\r\nOutside of business, Borg is a devoted family man. He enjoys spending time with his wife and children, living a relatively private life away from the public eye. Despite stepping back from professional tennis, he remains connected to the sport, often participating in tennis exhibitions and mentoring young players. Björn Borg's legacy continues to inspire new generations of players and fans around the globe, proving that his influence extends far beyond his years on the court.",
                cover_image:
                    'https://memora-api.mysu.pro/backend/media/f725c4c8cb9b4a07b764474aa7e812f9.png',
            },
            status: 'prebid',
            last_bid: 500,
            total_bids: 5,
            is_liked: false,
        },
    ]

    const quantity = 6

    const demoDuplicatedLots = demoLots.flatMap(obj =>
        [...Array(quantity)].map(() => obj),
    )

    return (
        <div>
            <UserHero />

            <Container
                style={{
                    paddingBottom: '24px',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                }}>
                <TabsComponent
                    tabs={tabs}
                    active={activeTab}
                    style={{
                        whiteSpace: 'nowrap',
                        overflowX: 'auto',
                        marginBottom: '40px',
                    }}
                />

                <div className="position-relative">
                    {activeTab === 'watchlist' && <WatchList /> }
                    {activeTab === 'myBids' && <BidList type={'myBids'} />}
                    {activeTab === 'owned' && <OwnedList type={'owned'} />}
                    {activeTab === 'myImpact' && (
                        <ImpactList type={'myImpact'} />
                    )}

                    <div
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                        }}></div>
                </div>
            </Container>
        </div>
    )
}
