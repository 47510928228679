import Hero from 'src/components/hero'

import { Container } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import AuctionList from 'src/components/auction/action-list'
import { useEffect, useState } from 'react'
import TabsComponent from 'src/components/tabs'
import SortDrowdown from 'src/components/sort-drowdown'

export default function Home() {
    const [activeTab, setActiveTab] = useState('current')

    const location = useLocation()

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const tab = params.get('tab') || 'current'
        setActiveTab(tab)
    }, [location.search])

    const tabs = [
        {
            name: 'Current',
            key: 'current',
        },
        // {
        //     name: 'Upcoming',
        //     key: 'upcoming',
        // },
        // {
        //     name: 'Past',
        //     key: 'past',
        // },
    ]
    return (
        <div>
            <Hero />

            <Container

                style={{
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    paddingBottom: '24px',
                }}>
                <TabsComponent tabs={tabs} active={activeTab} />
                <div className="position-relative">
                    {/* <SortDrowdown
                        options={[
                            'Auction date',
                            'Option 1',
                            'Option 2',
                            'Option 3',
                        ]}
                        initialSort={'Auction date'}
                    /> */}
                    {activeTab === 'current' && (
                        <AuctionList type={'current'} />
                    )}

                    {activeTab === 'upcoming' && (
                        <AuctionList type={'upcoming'} />
                    )}

                    {activeTab === 'past' && <AuctionList type={'past'} />}
                </div>
            </Container>
        </div>
    )
}
