import React from 'react'
import ImpactCard from '../user-my-impact'
import styles from './style.module.css'
import { useMyOwnedList } from 'src/lib/hooks/user'
import EmptyList from '../empty-list'
const cardsArray = new Array(4).fill(null)
const ImpactList = () => {
    const [{ items }] = useMyOwnedList({});

    if (items.length == 0) return <EmptyList>
        <h4>You have not made any donations yet.</h4>
        <p>Browse and bid in auctions to start making an impact.</p>
    </EmptyList>;

    return (
        <div className={styles.impactCardsWrapper}>
            {items.map((el, i) => (
                <ImpactCard key={i} lot={el} />
            ))}
        </div>
    )
}

export default ImpactList
