import LotGallery from 'src/components/lot/lot-gallery'
import { Col, Container, Row } from 'react-bootstrap'
import LotDescription from 'src/components/lot/lot-description'
import LotExplore from 'src/components/lot/lot-explore'
import { lotsSample } from 'src/data/lots'
import { useParams } from 'react-router'
import { useLot, useLotsList } from 'src/lib/hooks/lots'
import Bidding from '../components/bidding'
import { useBidsRealtime } from 'src/lib/providers/bids'

export default function Lot() {
    const { uuid, lot_uuid } = useParams()
    const [{ item: lot, isLoading }] = useLot(lot_uuid, uuid)
    const [{ items: lots, isLoading: lotsLoading }] = useLotsList({
        auctionUUID: uuid,
    })

    const images = lot
        ? lot['gallery_media']
              .filter(m => m['media_type'] == 'image')
              .map(m => m['media_file'])
        : []

    // const images = [
    //     'https://via.placeholder.com/800x600',
    //     'https://via.placeholder.com/800x600',
    //     'https://via.placeholder.com/600x900',
    //     'https://via.placeholder.com/800x600',
    // ]

    return (
        <Container fluid>
            <Row
                style={{
                    position: 'relative',
                }}>
                <LotGallery images={images} />
                <LotDescription lot={lot} />
            </Row>
            <LotExplore lots={lots ?? []} auctionUUID={uuid} />

            {lot && <Bidding lot={lot} />}
        </Container>
    )
}
