import styles from './style.module.css'
import sampleLotImage from 'src/assets/images/sample-log.jpg'

import Label from 'src/components/label'
import { LotStatus } from '../../auction/auction-status'
import { useNavigate } from 'react-router'
import { useCallback, useState } from 'react'
import { useLotDisike, useLotLike } from 'src/lib/hooks/lots'
import { useAuth } from 'src/lib/providers/auth'
import { useModal } from 'src/lib/providers/modal'
import { MODAL } from 'src/components/layouts/Layout'

const statusConfig = {
    live: { variant: 'live', text: 'Live' },
    prebid: { variant: 'prebid', text: 'Pre-bid' },
    sold: { variant: 'sold', text: 'Sold' },
    closed: { variant: 'sold', text: 'Closed' }
}

const LotTags = ({ lot }) => {
    const { variant, text: statusText } = statusConfig[lot.status];

    return <div className={styles.tags}>
        <div className={`${styles.status} ${styles[variant]}`}>
            {statusText}
        </div>
        { (lot.status == 'prebid' || lot.status == 'live') && <div className={styles.views}>{lot['views_count'] || 0} views</div> }
    </div>;
}

const LotCard = ({ lot }) => {
    const [isLiked, setIsLiked] = useState(lot['is_liked']);
    const navigate = useNavigate();
    const [{ like }] = useLotLike(lot.uuid);
    const [{ dislike }] = useLotDisike(lot.uuid);
    const { isLoggedIn } = useAuth();
    const { openModal } = useModal();

    const toggleLike = useCallback(() => {
        if (isLoggedIn) {
            if (!isLiked) {
                like().then(() => setIsLiked(true))
            } else {
                dislike().then(() => setIsLiked(false))
            }
        } else {
            openModal(MODAL.LOGIN);
        }
    }, [isLiked, isLoggedIn]);

    return (
        <div className={styles.card}>
            <div className={styles.content}>
                <div className={styles.imageWrapper}>
                    <LotTags lot={lot} />
                    <div
                        onClick={toggleLike}
                        className={`${styles.favourite} ${isLiked ? styles.favouriteActive : ''}`}>
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle
                                cx="24"
                                cy="24"
                                r="23.5"
                                fill="white"
                                stroke="#EDE9E3"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M24.0504 17.9343C22.2397 15.8174 19.2202 15.248 16.9514 17.1864C14.6827 19.1249 14.3633 22.3659 16.1449 24.6585C17.4906 26.3901 21.3136 29.8665 23.1039 31.4641C23.433 31.7577 23.5975 31.9046 23.7902 31.9624C23.9575 32.0125 24.1434 32.0125 24.3106 31.9624C24.5033 31.9046 24.6679 31.7577 24.997 31.4641C26.7872 29.8665 30.6102 26.3901 31.9559 24.6585C33.7375 22.3659 33.4571 19.1045 31.1494 17.1864C28.8417 15.2684 25.8611 15.8174 24.0504 17.9343Z"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <img alt={lot.title} src={lot['cover_media']['media_file']} onClick={() => navigate(`/auction/${lot.auction}/${lot.uuid}`)} />
                </div>
                <LotStatus lot={lot} containerCSS={styles.bidInfo} columnCSS={styles.bidColumn} />

            </div>
            <div className={styles.title}>{lot.title}</div>
        </div>
    )
}

export default LotCard
