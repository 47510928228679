import { Toast, ToastContainer } from 'react-bootstrap'
import styles from './style.module.css'
import { useEffect, useState } from 'react'

/*
<AlertMessage
   show={true}
    onClose={() => {}}
    message={'Something went wrong. Please try again.'}
    autoHide
    delay={5000}
/>
*/
const AlertMessage = ({
    show,
    onClose,
    message = 'Something went wrong. Please try again.',
    autoHide = true,
    delay = 3000,
}) => {
    const [isVisible, setIsVisible] = useState(show)

    useEffect(() => {
        setIsVisible(show)
    }, [show])

    useEffect(() => {
        if (autoHide && isVisible) {
            const timer = setTimeout(() => {
                setIsVisible(false)
                onClose()
            }, delay)

            return () => clearTimeout(timer)
        }
    }, [autoHide, isVisible, delay, onClose])

    return (
        <ToastContainer
            className={styles.container}
            position={'bottom-center'}
            style={{ zIndex: 1040 }}>
            <Toast
                onClose={() => {
                    setIsVisible(false)
                    onClose()
                }}
                className={styles.toast}
                show={isVisible}>
                <Toast.Body>
                    <div className="d-flex align-items-center">
                        <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect
                                width="32"
                                height="32"
                                rx="16"
                                fill="#FB1700"
                            />
                            <rect
                                x="14"
                                y="6"
                                width="4"
                                height="12"
                                rx="1"
                                fill="white"
                            />
                            <rect
                                x="14"
                                y="20"
                                width="4"
                                height="4"
                                rx="2"
                                fill="white"
                            />
                        </svg>
                        <div>{message}</div>
                    </div>
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

export default AlertMessage
