import styles from './style.module.css'
import { useModal } from 'src/lib/providers/modal'

export const VideoModal = () => {
    const { closeModal, context } = useModal()

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.closeIconBox} onClick={closeModal}>
                    <div onClick={closeModal} className={styles.closeIcon}>
                        <img src="/close-icon-black.svg" alt="Close" />
                    </div>
                </div>

                <video autoPlay muted loop controls id="sample-video">
                    <source src={context?.videoUrl} type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
            </div>
        </>
    )
}

export default VideoModal
