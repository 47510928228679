import styles from './style.module.css'
import moment from 'moment/moment'
import Label from '../../label'
import ScrollFade from '../../scroll-fade'
import BidStatus from '../bid-status'
import { useAuth } from 'src/lib/providers/auth'

export function formatDate(datestr) {
    return moment(datestr).format('MMMM DD, ha')
}

export function formatBidDate(datestr) {
    return moment(datestr).format('MM/DD/YY, hh:mm:ss a')
}

const RowPlaceholder = () => {
    return (
        <div className={styles.rowPlaceholder}>
            <div className={styles.rowPlaceholderAvatar}></div>
            <div className={styles.rowPlaceholderContent}>
                <div className={styles.rowPlaceholderText}></div>
                <div className={styles.rowPlaceholderText}></div>
            </div>
        </div>
    )
}

const LatestBids = ({ bids, user }) => {
    const displayBids = bids.map(b => {
        return {
            amount: b.amount,
            date: b['date_added'],
            name: b['is_anonymous'] ? '@anonymous' : b.user,
            isMine: [user.email, user.uuid].indexOf(b.user) !== -1,
        }
    })

    const isTopBidder = displayBids.length && displayBids[0].isMine
    const isPlacedBid = displayBids.find(b => b.isMine) !== null

    return (
        <>
            <div className={styles.head}>
                <div className={styles.heading}>Latest bids</div>
                {isPlacedBid && (
                    <div
                        className={`${isTopBidder ? styles.active : ''} ${styles.subheading}`}>
                        {!displayBids.length
                            ? 'No bids yet'
                            : isTopBidder
                              ? 'You are the top bidder'
                              : 'You have been outbid'}
                    </div>
                )}
            </div>
            <div
                className={`${styles.list} ${!displayBids.length ? styles.noBids : ''}`}>
                <ScrollFade />

                {!displayBids.length && (
                    <>
                        <RowPlaceholder />
                        <RowPlaceholder />
                        <RowPlaceholder />
                        <RowPlaceholder />
                        <div className={styles.noBidsText}>
                            There are currently no bids.
                        </div>
                    </>
                )}
                {displayBids.map((bid, index) => {
                    const reverseIndex = displayBids.length - index
                    let bidTypeClass = 0

                    if (bid.isMine) {
                        bidTypeClass = 'top' // outbid, top
                    }

                    return (
                        <div
                            key={reverseIndex}
                            className={`${styles.bidRow} ${styles[bidTypeClass]}`}>
                            <div className={styles.bidIndex}>
                                {reverseIndex}
                            </div>
                            <div className={styles.bidInfo}>
                                <div className={styles.bidUser}>{bid.name}</div>
                                <div className={styles.bidDate}>
                                    {formatBidDate(bid.date)}
                                </div>
                            </div>
                            <div className={styles.bidAmount}>
                                {bid.amount.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    maximumFractionDigits: 0,
                                    minimumFractionDigits: 0,
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default LatestBids
