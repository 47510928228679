import { Col, Container, Ratio, Row } from 'react-bootstrap'
import AuctionStatus from 'src/components/auction/auction-status'
import sampleVideo from 'src/assets/images/sample-video.mp4'
import styles from './style.module.css'
import Label from 'src/components/label'
import { useModal } from 'src/lib/providers/modal'
import { MODAL } from 'src/components/layouts/Layout'
import VideoWithModal from 'src/components/video-with-modal'

const AuctionDescription = ({ auction }) => {
    const { openModal } = useModal()

    return (
        <Container className={styles.container}>
            <Row>
                <Col md={12} lg={8}>
                    <div className={styles.titleContainer}>
                        <h1>{auction.title}</h1>
                    </div>

                    <AuctionStatus auction={auction} />

                    <Row className={styles.description}>
                        <Col md={12} lg={6}>
                            <Label text="Description" />
                            <div>
                                <p>
                                    {auction.description_short}...
                                    <a
                                        href="javascript:void(0)"
                                        onClick={() =>
                                            openModal(
                                                MODAL.CONTENT_MODAL,
                                                auction,
                                            )
                                        }>
                                        Learn more
                                    </a>
                                </p>
                            </div>
                        </Col>
                        <Col md={12} lg={6} className="mt-5 mt-lg-0">
                            <Label text="Foundation" />
                            <div>
                                <p>
                                    {auction.foundation.description_short}...
                                    <a
                                        href="javascript:void(0)"
                                        onClick={() =>
                                            openModal(
                                                MODAL.CONTENT_MODAL,
                                                auction.foundation,
                                            )
                                        }>
                                        Learn more
                                    </a>
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div></div>
                </Col>
                {auction.promo_video && (
                    <Col md={12} lg={4} className="mt-5 mt-lg-0">
                        <VideoWithModal
                            url={auction['promo_video']}
                            thumbnail={
                                auction['promo_video_thumbnail']
                            }
                        />
                    </Col>
                )}
            </Row>
        </Container>
    )
}

export default AuctionDescription
