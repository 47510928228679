import { api } from "../api/client"
import { useState, useEffect } from "react";
import { useAuth } from "../providers/auth";

export const useLotsList = ({ auctionUUID, limit=10, offset=0, ordering=""}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(undefined);
  const { access } = useAuth();

  const request = (params) => {
    const opts = {
      params
    };

    if (access) opts.headers = {
      'Authorization': `Bearer ${access}`
    };

    return api.get("/lots/", opts);
  }

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setIsLoading(true);

      try {
        const d = await request({ auction: auctionUUID, limit, offset, ordering });
        setData(d);
      } catch (error) {
        setError(error);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [auctionUUID, limit, offset, ordering]);

  return [{ items: data?.results || [], isLoading, error }];
}

export const useLot = (uuid, auction) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(undefined);
  const { access } = useAuth();

  // const request = (uuid) => api.get(`/lots/${uuid}/`, { params: { auction } });

  const request = (uuid) => {
    const opts = {

    };

    if (access) opts.headers = {
      'Authorization': `Bearer ${access}`
    };

    return api.get(`/lots/${uuid}/`, opts);
  }

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setIsLoading(true);

      try {
        const d = await request(uuid);
        setData(d);
      } catch (error) {
        setError(error);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [uuid]);

  return [{ item: data, isLoading, error }];
}

export const useLotLike = (uuid) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(undefined);
  const { access } = useAuth();

  async function like() {
    setError(null);
    setIsLoading(true);

    try {
      const response = api.post(`/lots/${uuid}/like/`, {}, {
        headers: {
          'Authorization': `Bearer ${access}`
        }
      });
      setData(response);
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  }

  return [{ like, item: data, isLoading, error }];
}

export const useLotDisike = (uuid, auctionUUID) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(undefined);
  const { access } = useAuth();

  async function dislike() {
    setError(null);
    setIsLoading(true);

    try {
      const response = api.post(`/lots/${uuid}/dislike/`, {}, {
        headers: {
          'Authorization': `Bearer ${access}`
        }
      });
      setData(response);
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  }

  return [{ dislike, item: data, isLoading, error }];
}