import React, { useCallback, useState } from 'react'
import styles from './style.module.css'
import SidebarMenu from 'react-bootstrap-sidebar-menu'
import { SidebarMenuFooter } from 'react-bootstrap-sidebar-menu'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import BackIcon from 'src/assets/images/backIcon'
import { useAuth } from 'src/lib/providers/auth'
import { Navigate, useNavigate } from 'react-router'
import PathConstants from 'src/routes/pathConstants'
import { useSearchParams } from 'react-router-dom'
import ProfileInformationPage from './information'
import PasswordPage from './password'
import DeliveryPage from './delivery'
import PaymentPage from './payment'
import EmailNotificationPage from './email-notification'
const menuItems = [
    {
        title: 'Profile information',
        state: 'active',
    },
    {
        title: 'Password',
        state: 'inactive',
    },
    {
        title: 'Delivery address',
        state: 'inactive',
    },
    {
        title: 'Payment',
        state: 'inactive',
    },
    {
        title: 'Email notifications',
        state: 'inactive',
    },
]

const tabs = [
    {
        title: 'Profile information',
        id: 'info',
    },
    {
        title: 'Password',
        id: 'password',
    },
    {
        title: 'Delivery address',
        id: 'address',
    },
    {
        title: 'Payment',
        id: 'payment',
    },
    {
        title: 'Email notifications',
        id: 'notifications',
    },
]

const UserProfileLayout = () => {
    const { user } = useAuth()
    const [searchParams, setSearchParams] = useSearchParams()
    const initialTabId = searchParams.get('tab') || 'info'
    const [currentTabId, setCurrentTabId] = useState(initialTabId)
    const navigate = useNavigate()

    if (!user) return <Navigate to={PathConstants.HOME} />

    function activateTab(tabId) {
        searchParams.set('tab', tabId)
        setSearchParams(searchParams)
        setCurrentTabId(tabId)
    }

    return (
        <div>
            <div style={{ display: 'flex', minHeight: '100vh' }}>
                {/* <Navbar expand="xxl" className={styles.nav}>
                    <Container fluid>
                        <Navbar
                            id="basic-navbar-nav"
                            style={{
                                width: '100%',
                            }}>
                            <Nav className={styles.navBox}>
                                <Nav.Link href="/" className={styles.home}>
                                    Home
                                </Nav.Link>
                                <Navbar.Brand>
                                    <img
                                        src="/Memora_Logo.png"
                                        className={styles.brandImage}
                                    />
                                </Navbar.Brand>
                                <Nav.Item className={styles.navPhoto}>
                                    <img src="/little_Mona.png" />
                                </Nav.Item>
                            </Nav>
                        </Navbar>
                    </Container>
                </Navbar> */}
                <SidebarMenu className={styles['sidebar-menu']}>
                    <SidebarMenu.Header className={styles['header']}>
                        <SidebarMenu.Brand className={styles.title} />
                        {/* <SidebarMenu.Nav.Link
                            href="/"
                            className={styles.back}
                        /> */}
                    </SidebarMenu.Header>
                    <SidebarMenu.Body className={styles.sidebarbody}>
                        <SidebarMenu.Sub>
                            <SidebarMenu.Nav>
                                <SidebarMenu.Nav.Link
                                    onClick={() =>
                                        navigate(PathConstants.PROFILE)
                                    }
                                    className={styles.back}
                                />
                                {tabs.map(tab => (
                                    <SidebarMenu.Nav.Item
                                        className={styles.stage}
                                        key={tab.id}
                                        onClick={() => activateTab(tab.id)}>
                                        <SidebarMenu.Sub
                                            as="div"
                                            className={`${styles['icon-stage']} ${
                                                tab.id === currentTabId &&
                                                styles.active
                                            }`}
                                        />
                                        <SidebarMenu.Nav.Title
                                            className={`${styles.stageTitle} ${
                                                tab.id === currentTabId &&
                                                styles.active
                                            }`}>
                                            {tab.title}
                                        </SidebarMenu.Nav.Title>
                                    </SidebarMenu.Nav.Item>
                                ))}
                            </SidebarMenu.Nav>
                        </SidebarMenu.Sub>
                    </SidebarMenu.Body>
                    <SidebarMenuFooter>
                        <SidebarMenu.Nav.Link className={styles.footer}>
                            Delete account
                        </SidebarMenu.Nav.Link>
                    </SidebarMenuFooter>
                </SidebarMenu>
                {currentTabId === 'info' && <ProfileInformationPage />}
                {currentTabId === 'password' && <PasswordPage />}
                {currentTabId === 'address' && <DeliveryPage />}
                {currentTabId === 'payment' && <PaymentPage />}
                {currentTabId === 'notifications' && <EmailNotificationPage />}
                {/* {children} */}
            </div>
        </div>
    )
}

export default UserProfileLayout
