import styles from './style.module.css'
import { MODAL } from 'src/components/layouts/Layout'
import PlayIcon from 'src/assets/images/playIcon'
import { useModal } from 'src/lib/providers/modal'
const VideoWithModal = ({ url, thumbnail, height = 291 }) => {
    const { openModal } = useModal()
    return (
        <div
            onClick={() => openModal(MODAL.VIDEO, { videoUrl: url })}
            className={styles.container}
            style={{
                backgroundImage: `url(
                                ${thumbnail}
                            )`,
                height: `${height}px`,
            }}>
            <PlayIcon />
        </div>
    )
}

export default VideoWithModal
