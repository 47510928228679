import { api } from "../api/client"
import { useState, useEffect } from "react";
import { useAuth } from "../providers/auth";
import { useToast } from "../providers/toast";

export const useBidsList = ({ lotID, limit=100, offset=0, ordering=""}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(undefined);

  const request = (params) => api.get("/bids/", {
    params
  });

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setIsLoading(true);

      try {
        const d = await request({ lot: lotID, limit, offset, ordering });
        setData(d);
      } catch (error) {
        setError(error);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [lotID, limit, offset, ordering]);

  return [{ items: data?.results || [], isLoading, error }];
}

export const useBid = (uuid, auction) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(undefined);

  const request = (uuid) => api.get(`/bids/${uuid}/`);

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setIsLoading(true);

      try {
        const d = await request(uuid);
        setData(d);
      } catch (error) {
        setError(error);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [uuid]);

  return [{ item: data, isLoading, error }];
}

export const usePlaceBid = (lotID) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(undefined);
  const { access } = useAuth();
  const { showToast } = useToast();

  async function placeBid({ isAnonymous, amount }) {
    setError(null);
    setIsLoading(true);

    try {
      const response = await api.post(`/bids/`, {
        'is_anonymous': isAnonymous,
        amount,
        'lot': lotID
      }, {
        headers: {
          'Authorization': `Bearer ${access}`
        }
      });
      setData(response);
    } catch (error) {
      showToast(error.response.data.text);
      setError(error);
    }

    setIsLoading(false);
  }

  return [{ placeBid, item: data, isLoading, error }];
}