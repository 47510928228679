import { useTimeRemaining } from 'src/components/auction/auction-status';
import { formatDate } from '../latest-bids';
import styles from './style.module.css'
import Label from 'src/components/label'

const BidStatus = ({ lot, bids }) => {
    // get data from react context?

    const lastBid = bids.length && bids[0];
    const { timeTo } = useTimeRemaining(lot['date_end']);

    return (
        <div className={styles.status}>
            <div>
                <Label text="Ends" />
                <div>{formatDate(lot['date_end'])}</div>
            </div>
            <div>
                <Label text="Remaining" />
                <div>{timeTo}</div>
            </div>
            <div>
                <Label text="Current bid" />
                <div>{ (lastBid?.amount ?? 0).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                })}</div>
            </div>
            <div>
                <Label text="Total bids" />
                <div>{bids.length}</div>
            </div>
        </div>
    )
}

export default BidStatus
