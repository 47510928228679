import React from 'react'
import BidCard from '../user-bid-card'
import { useMyBidsList } from 'src/lib/hooks/user'
import EmptyList from '../empty-list'
const bidArray = new Array(3).fill(null)

const BidList = () => {
    const [{ items }] = useMyBidsList({});

    if (items.length == 0) return <EmptyList>
        <h4>You currently don't have any active bids.</h4>
        <p>Start participating in auctions to place your bids and track your activity here.</p>
    </EmptyList>;

    return (
        <>
            {items.map((el, i) => (
                <BidCard key={i} isHighestBidder={el['last_bid'] == el['my_last_bid']} bid={el} />
            ))}
        </>
    )
}

export default BidList
