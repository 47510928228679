import { createContext, useState, useContext } from 'react'

const toastContext = createContext({})

export function ToastProvider({ children }) {
    const toast = useProvideToast()

    return (
        <toastContext.Provider value={toast}>{children}</toastContext.Provider>
    )
}

export const useToast = () => useContext(toastContext)

function useProvideToast() {
    const [message, setMessage] = useState('');

    function clearToast() {
      setMessage('');
    }

    function showToast(message) {
      setMessage(message);
    }

    return { message, clearToast, showToast }
}
