import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './style.module.css'
import Button from 'src/components/button'
import { useAuth } from 'src/lib/providers/auth'
import { avatarStub } from 'src/lib/shared/constants'
import { useNavigate } from 'react-router'
import PathConstants from 'src/routes/pathConstants'

const UserHero = () => {
    const { user } = useAuth()
    const navigate = useNavigate()

    const name = user?.first_name || user?.username
    const greetings = name ? `, ${name}` : ''

    return (
        <div className={`${styles.heroUserSection}`}>
            <Container>
                <Row>
                    <Col>
                        <div className={`${styles.titleImageBox}`}>
                            <h1>Welcome back{greetings}</h1>
                            <div
                                style={{
                                    width: '212px',
                                    height: '212px',
                                    borderRadius: '50%',
                                    backgroundImage: `url(${user?.avatar ? user?.avatar : avatarStub})`, //рабочий вариант, когда решится вопрос с корректной загрузкой картинок
                                    // backgroundImage: `url(https://i.pinimg.com/736x/3f/f1/20/3ff120824122616c4922797749f58fba.jpg)`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundColor: '#EDE9E3',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    position: 'relative',
                                }}>
                                {/* <div className={`${styles.verified}`}></div> */}
                                <div className={`${styles.imageButton}`}>
                                    <Button
                                        variant="neutral-light"
                                        fullWidth={false}
                                        onClick={() =>
                                            navigate(PathConstants.PROFILE_EDIT)
                                        }>
                                        Edit profile
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.verifyText}>
                            {/* <h4
                                style={{
                                    fontSize: '36px',
                                    lineHeight: '41px',
                                    marginBottom: '8px',
                                }}>
                                Not verified
                            </h4>
                            <p>
                                Complete your profile to start the verification
                            </p>

                            <p> process and begin placing bids.</p> */}
                            {/* <Button
                                variant="primary"
                                style={{ marginTop: '16px' }}
                                fullWidth={false}>
                                Start verification
                            </Button> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default UserHero
