import styles from './style.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useCallback, useEffect, useRef, useState } from 'react'
import 'swiper/css'
import ArrowPrev from 'src/assets/images/arrow-prev'
import ArrowNext from 'src/assets/images/arrow-next'
import LotCard from '../lot-card'
import { Link, useNavigate } from 'react-router-dom'
import Button from 'src/components/button'
import { MOBILE_BREAKPOINT } from 'src/config'

const LotExplore = ({ lots, auctionUUID }) => {
    const swiperRef = useRef(null)
    const navigate = useNavigate()
    const [isMobile, setIsMobile] = useState(false)
    const isSliderView = !isMobile

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < MOBILE_BREAKPOINT) // Adjust the breakpoint as needed
        }

        handleResize() // Check on initial load
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const handlePrev = useCallback(() => {
        if (!swiperRef.current) return
        swiperRef.current.swiper.slidePrev()
    }, [])

    const handleNext = useCallback(() => {
        if (!swiperRef.current) return
        swiperRef.current.swiper.slideNext()
    }, [])

    return (
        <Container className={styles.wrapper}>
            <div className={styles.header}>
                <h3>Explore more lots</h3>

                {isSliderView && (
                    <div className="d-flex align-items-center">
                        <div className={styles.swiperNavigation}>
                            <div
                                className={styles.navigationArrow}
                                onClick={handlePrev}>
                                <ArrowPrev />
                            </div>
                            <div
                                className={styles.navigationArrow}
                                onClick={handleNext}>
                                <ArrowNext />
                            </div>
                        </div>
                        <Link to="#">
                            <Button
                                variant="neutral-dark"
                                onClick={() =>
                                    navigate(`/auction/${auctionUUID}`)
                                }>
                                View all lots
                            </Button>
                        </Link>
                    </div>
                )}
            </div>
            {isSliderView ? (
                <Swiper
                    ref={swiperRef}
                    spaceBetween={22}
                    loop
                    slidesPerView={3}>
                    {lots.map((lot, index) => (
                        <SwiperSlide key={index}>
                            <LotCard lot={lot} auctionUUID={auctionUUID} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <>
                    {lots.map((lot, index) => (
                        <Col
                            key={index}
                            md={12}
                            className={styles.desktopImage}>
                            <LotCard lot={lot} auctionUUID={auctionUUID} />
                        </Col>
                    ))}
                    <Link to="#">
                        <Button
                            variant="neutral-dark"
                            onClick={() => navigate(`/auction/${auctionUUID}`)}>
                            View all lots
                        </Button>
                    </Link>
                </>
            )}
        </Container>
    )
}
export default LotExplore
