import React, { useState } from 'react'
import styles from './style.module.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'

import Button from 'src/components/button'
const countries = [
    'Russia',
    'China',
    'Canada',
    'Germany',
    'France',
    'Japan',
    'United Kingdom',
    'United States',
    'Russia',
    'China',
    'Canada',
    'Germany',
    'France',
    'Japan',
    'United Kingdom',
    'United States',
]

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAuth } from 'src/lib/providers/auth'
import { useProfileUpdate, useTaxCountries } from 'src/lib/hooks/user'
import { FormGroup } from 'react-bootstrap'

const AddressEditSchema = Yup.object().shape({
    'delivery_address': Yup.string().nullable().max(200, "200 characters or fewer"),
    'delivery_city': Yup.string().nullable().max(100, "100 characters or fewer"),
    'delivery_country': Yup.string().nullable(),
    'delivery_zip': Yup.string().nullable().max(10, "10 characters or fewer"),
});

const DeliveryPage = () => {
    const { user } = useAuth();
    const [{ items: countries }] = useTaxCountries();
    const { profileUpdate, error: updateError } = useProfileUpdate();

    const formik = useFormik({
        initialValues: {
            'delivery_address': user['delivery_address'],
            'delivery_city': user['delivery_city'],
            'delivery_country': user['delivery_country'],
            'delivery_zip': user['delivery_zip']
        },
        validationSchema: AddressEditSchema,
        // validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async values => {
            // console.log("Run submit");
            // console.log(values);
            profileUpdate(values);
            // updateProfile(values);
        }
    });

    const selectedCountryName = countries.find(c => c.slug == formik.values['delivery_country'])?.name;

    const handleCountrySelect = country => {
        formik.setFieldValue('delivery_country', country);
        // setSelectedCountry(country)
    }
    return (
        <>
            <div className={styles.passwordUserWrapper}>
                <img className={styles.backIconMobile} />
                <div className={styles.InformationItem}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>
                        <h4>Delivery address</h4>
                    </div>
                    <div className={styles.formContainer}>
                        <p
                            style={{
                                fontFamily: 'GT Pressura, monospace',
                                marginBottom: '16px',
                                fontSize: '12px',
                                lineHeight: '12px',
                            }}>
                            ADDRESS
                        </p>
                        <Container className={styles.informationUserContainer}>
                            <Form style={{ minHeight: 'auto' }} onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Address"
                                                className={styles.userPageInput}
                                                name="delivery_address"
                                                value={formik.values.delivery_address || ''}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.delivery_address}
                                            />
                                            <Form.Control.Feedback type="invalid">{formik.errors.delivery_address}</Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="City"
                                                className={styles.userPageInput}
                                                name="delivery_city"
                                                value={formik.values.delivery_city || ''}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.delivery_city}
                                            />
                                            <Form.Control.Feedback type="invalid">{formik.errors.delivery_city}</Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Zip Code"
                                                className={styles.userPageInput}
                                                name="delivery_zip"
                                                value={formik.values.delivery_zip || ''}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.delivery_zip}
                                            />
                                            <Form.Control.Feedback type="invalid">{formik.errors.delivery_zip}</Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Form.Control
                                                type="hidden"
                                                placeholder=""
                                                className={styles.userPageInput}
                                                name="countryChecker"
                                                value={formik.values['delivery_country'] || ''}
                                                // onChange={formikInfo.handleChange}
                                                isInvalid={!!formik.errors['delivery_country']}
                                            />
                                            <Dropdown
                                                onSelect={handleCountrySelect}>
                                                <Dropdown.Toggle
                                                    className={`${styles.dropdownToggle} ${formik.errors['delivery_country'] ? styles.dropdownMenuError : ''} ${styles.userPageInput} w-100 d-flex justify-content-between align-items-center`}
                                                    id="tax-residency-country-dropdown">
                                                    <span
                                                        className={`text-start ${!formik.values['delivery_country'] ? styles.inactiveText : ''}`}>
                                                        {selectedCountryName ||
                                                            'Country'}
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    className={`w-100 `}
                                                    style={{
                                                        maxHeight: '192px',
                                                        overflowY: 'auto',
                                                    }}>
                                                    {countries.map(
                                                        (country) => (
                                                            <Dropdown.Item
                                                                key={country.slug}
                                                                eventKey={country.slug}>
                                                                {country.name}
                                                            </Dropdown.Item>
                                                        ),
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Form.Control.Feedback type="invalid">{formik.errors['delivery_country']}</Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className={styles.buttonBox}>
                                    <Button>Update address</Button>
                                </div>
                            </Form>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeliveryPage
