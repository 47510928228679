import React from 'react'
import OwnedCard from '../user-owned-card'
import { useMyOwnedList } from 'src/lib/hooks/user'
import EmptyList from '../empty-list'

const cardsArray = new Array(3).fill(null)

const OwnedList = () => {
    const [{ items }] = useMyOwnedList({});

    if (items.length == 0) return <EmptyList>
        <h4>You currently don't have any items yet.</h4>
        <p>Browse and bid in auctions to start building your collection.</p>
    </EmptyList>;

    return (
        <>
            {items.map((el, i) => (
                <OwnedCard key={i} lot={el} isHighestBidder={Math.random() >= 0.5} />
            ))}
        </>
    )
}

export default OwnedList
