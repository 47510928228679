import { useCallback, useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './style.module.css'
import reviewStyles from './review-bid/style.module.css';
import Button from 'src/components/button'
import Label from 'src/components/label'
import LatestBids from './latest-bids'
import BidForm from './bid-form'
import ReviewBid from './review-bid'
import BidStatus from './bid-status'
import Winner from './winner'
import { IS_MOBILE } from '../../config'
import LotInfo from './lot-info'
import { useBidsList } from 'src/lib/hooks/bids'
import { useAuth } from 'src/lib/providers/auth'
import { useBidsRealtime } from 'src/lib/providers/bids'
import { useModal } from 'src/lib/providers/modal'
import { MODAL } from '../layouts/Layout'
import { useSearchParams } from 'react-router-dom';

const Bidding = ({ lot }) => {
    const [searchParams] = useSearchParams();
    const [expanded, setExpanded] = useState(!!searchParams.get('show_bids'))
    const [step, setStep] = useState(0) // 0 initial, 1 review, 2 win
    const [bids, setBids] = useState([])
    const [{ items: bidsHistory, isLoading }] = useBidsList({ lotID: lot.uuid })
    const { user, isLoggedIn } = useAuth()
    const { openModal } = useModal()
    const [isPlaced, setIsPlaced] = useState(false);

    useBidsRealtime(bid => {
        setBids(bids => {
            const isRelated = lot.uuid == bid.lot
            const existing = bids.indexOf(b => b.uuid == bid.uuid) !== -1
            // console.log("ADD BID", bids, bid);
            if (!existing && isRelated) return [bid].concat(bids.filter(b => b.uuid != bid.uuid))
            return bids
        })
    })

    useEffect(() => {
        setBids(bidsHistory)
    }, [lot.uuid, isLoading])

    // const toggleExpand = () => {
    //     setExpanded(!expanded)
    // }

    const toggleExpand = useCallback(() => {
        if (isLoggedIn) setExpanded(!expanded)
        else openModal(MODAL.LOGIN)
    }, [isLoggedIn, expanded])

    const onBid = () => {
        setIsPlaced(true);
        setTimeout(() => setIsPlaced(false), 2000);
    }

    const currentBid = bids.length ? bids[0] : null;

    const currentAmount = (currentBid?.amount ?? 0).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    })

    const text = '$8500'

    return (
        <>
            <div
                className={`${styles.overlay} ${expanded ? styles.visible : ''}`}
                onClick={toggleExpand}></div>
            <div
                className={`${styles.bottomBar} ${expanded ? styles.expanded : ''}`}>
                <div
                    className={`${styles.bottomBarContent} ${expanded ? styles.hidden : ''}`}>
                    <Container>
                        {step === 2 ? (
                            <Row className="align-items-center">
                                <Col
                                    xs={12}
                                    lg={8}
                                    className={styles.contentLeft}>
                                    <div className={styles.winnerText}>
                                        You’ve won!
                                    </div>
                                    <div className={styles.animatedContainer}>
                                        <div className={styles.animatedRow}>
                                            <span
                                                className={styles.animatedText}>
                                                {text.repeat(15)}
                                            </span>
                                            <span
                                                className={styles.animatedText}>
                                                {text.repeat(15)}
                                            </span>
                                        </div>
                                        <div className={styles.animatedRow}>
                                            <span
                                                className={styles.animatedText}>
                                                {text.repeat(15)}
                                            </span>
                                            <span
                                                className={styles.animatedText}>
                                                {text.repeat(15)}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    xs={12}
                                    lg={4}
                                    className={styles.buttonsRight}>
                                    <Button
                                        fullWidth={false}
                                        variant="dark"
                                        onClick={toggleExpand}>
                                        View all bids
                                    </Button>
                                    <Button
                                        fullWidth={false}
                                        variant="primary"
                                        onClick={toggleExpand}>
                                        View status
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            <Row className="align-items-center">
                                <Col
                                    xs={12}
                                    lg={8}
                                    className={styles.contentLeft}>
                                    <div className={styles.bidValueColumn}>
                                        <Label text={'Current bid'} />
                                        <div className={styles.bidValue}>
                                            {currentAmount}
                                        </div>
                                    </div>
                                    <div className={styles.bidValueColumn}>
                                        <Label text={'Total bids'} />
                                        <div className={styles.bidValue}>
                                            {bids.length}
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    xs={12}
                                    lg={4}
                                    className={styles.buttonsRight}>
                                    <Button
                                        fullWidth={false}
                                        variant="dark"
                                        onClick={toggleExpand}>
                                        View all bids
                                    </Button>
                                    <Button
                                        fullWidth={false}
                                        variant="primary"
                                        onClick={toggleExpand}>
                                        Place bid
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </div>
                <div className={styles.expandedContent}>
                    <div className={styles.close} onClick={toggleExpand}>
                        <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.75 0.75L10.5 10.5"
                                stroke="#FF7F47"
                                strokeWidth="1.5"
                            />
                            <path
                                d="M10.5 0.75L0.75 10.5"
                                stroke="#FF7F47"
                                strokeWidth="1.5"
                            />
                        </svg>
                    </div>
                    <Container className={styles.desktopHeight100}>
                        {step === 0 && (
                            <Row
                                className={`flex-column-reverse flex-lg-row ${styles.desktopHeight100}`}>
                                <Col xs={12} lg={6}>
                                    {user && (
                                        <LatestBids bids={bids} user={user} />
                                    )}
                                    {!IS_MOBILE && (
                                        <BidStatus lot={lot} bids={bids} />
                                    )}
                                </Col>
                                <Col
                                    xs={12}
                                    lg={6}
                                    className={styles.desktopHeight100}>
                                    {IS_MOBILE && <LotInfo lot={lot} />}
                                    {IS_MOBILE && (
                                        <BidStatus lot={lot} bids={bids} />
                                    )}
                                    { isPlaced
                                    ? <div className={reviewStyles.container}>
                                        <div className={reviewStyles.amount}>
                                            {'Bid placed'}
                                        </div>
                                    </div>
                                    : <BidForm
                                        setIsPlaced={onBid}
                                        // setStep={setStep}
                                        lot={lot}
                                        bids={bids}
                                    />
                                    }
                                </Col>
                            </Row>
                        )}
                        {step === 1 && (
                            <ReviewBid setStep={setStep} lot={lot} />
                        )}
                        {step === 2 && <Winner />}
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Bidding
