import styles from './style.module.css'
import { useModal } from 'src/lib/providers/modal'
import Label from '../../label'
import ScrollFade from '../../scroll-fade'

export const DigitalIdModal = () => {
    const { closeModal, context } = useModal()

    const sampleData = {
        "CONDITION": 'Pre-owned: An item that has been previously used. ',
        "TYPE": 'Boxing Shorts ',
        "BRAND": 'Everlast  ',
        "MATERIAL": 'Sequins, 100% pure silk lining ',
        "WEIGHT": '330g ',
        "MEMORABLE EVENT": 'Last fight - December 11, 1981 ',
        "AUTHENTICATION": 'By Memora ',
        "PROVENANCE": 'Muhammad Ali ',
        "DATE": '01.08.24 ',
        "TOKEN ID": '0x5A8B4D93F7E2C1D2 ',
        "SERIAL NUMBER": 'MUHAM2026SHORTO01 ',
    }
    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.closeIconBox} onClick={closeModal}>
                    <div onClick={closeModal} className={styles.closeIcon}>
                        <img src="/close-icon-black.svg" alt="Close" />
                    </div>
                </div>
                <div className={styles.titleImageBox}>
                    <h3>Digital ID</h3>
                    {context?.cover_media && (
                        <div className={styles.image}>
                            <img
                                src={context?.cover_media?.media_file}
                                alt={context?.title}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.content}>
                    <ScrollFade />
                    <div className={styles.contentTitle}>Item details</div>
                    <div className={styles.contentRow}>
                        <Label text={'Condition'} />
                        <div>
                            Pre-owned: An item that has been used previously.
                        </div>
                    </div>
                    <div className={styles.propsGrid}>
                        {Object.keys(sampleData).map(key => {
                            return (
                                <div key={key} className={styles.contentRow}>
                                    <Label text={key} />
                                    <div>{sampleData[key]}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalIdModal
